
            @import 'src/styles/mixins.scss';
        
.section {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    &::before {
        content: "";
        left: 50%;
        @include handleAllBreakpoints("width", "backgroundWidth", "100vw");
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -1;
        // height: calc(100% + var(--header-height, 50px));
        // margin-top: calc(var(--header-height, 50px) * -1);

        @include handleAllBreakpoints("background-color", "backgroundColor");
        @include handleAllBreakpoints("background", "backgroundImage", "var(--formBackground-sm)");
        @include handleAllBreakpoints("background-color", "backgroundColor", "var(--formBackground-sm)");
        @include handleAllBreakpoints("background-size", "backgroundSize", "cover");
        @include handleAllBreakpoints("background-repeat", "backgroundRepeat", "no-repeat");
        @include handleAllBreakpoints("background-position", "backgroundPosition", "center");
    }

    @include handleAllBreakpoints("margin", "margin", "0");
    @include handleAllBreakpoints("padding", "padding", "40px 10px");
    @include handleAllBreakpoints("gap", "gap", "20px 0::20px");

    .component {
        width: 100%;
        position: relative;
        grid-column: auto/span var(--columnSpan-sm, 12);

        @include md {
            grid-column: auto/span var(--columnSpan-md, 12);
        }

        @include lg {
            grid-column: auto/span var(--columnSpan-lg, 12);
        }

        @include xl {
            grid-column: auto/span var(--columnSpan-xl, 12);
        }

        @include xxl {
            grid-column: auto/span var(--columnSpan-xxl, 12);
        }

        @include handleAllBreakpoints("order", "componentOrder");
        @include handleAllBreakpoints('display', 'display', 'unset');

        @include handleAllBreakpoints("background", "componentContainerBackgroundImage");
        @include handleAllBreakpoints("background-color", "componentContainerBackgroundColor");
        @include handleAllBreakpoints("background-size", "componentContainerBackgroundSize");

        @include handleAllBreakpoints("background-repeat", "componentContainerBackgroundRepeat");
        @include handleAllBreakpoints("background-position", "componentContainerBackgroundPosition");
        @include handleAllBreakpoints("margin", "componentContainerMargin", "0 auto");
        @include handleAllBreakpoints("padding", "componentContainerPadding");
        @include handleAllBreakpoints("border", "componentContainerBorder", "none");
        @include handleAllBreakpoints("border-radius", "componentContainerBorderRadius", "0px");
        @include handleAllBreakpoints("box-shadow", "componentContainerBoxShadow", "none", "!important");
        @include handleAllBreakpoints("max-width", "componentContainerMaxWidth", "100%");
        @include handleAllBreakpoints("max-height", "componentContainerMaxHeight", "100%");
    }

    &:first-child {
        min-height: calc(100vh - var(--header-height, 50px));

        &::before {
            height: calc(100% + var(--header-height, 50px));
            margin-top: calc(var(--header-height, 50px) * -1);
        }
    }
}
